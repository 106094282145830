<template>
  <div>
    <h1>{{ isHR ? 'HR' : 'System' }} Administration</h1>
    <div v-if="!isHR" id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <div>
      <div
        class="row"
        style="margin-bottom: 8px;"
      >
        <div class="col-6">
          <!-- add button -->
          <div v-if="!isHR">
            <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                style="
                  justify-content: space-around;
                  display: flex;
                  border-radius: 0;
                "
                @click="$router.push('/admin/users-and-permissions/users/add')"
              >
                <span>Add User</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'fullName'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.LastName }}, {{ props.row.FirstName }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span><router-link
                    :to="(isHR ? '/hr/users/edit/' : '/admin/users-and-permissions/users/edit/') + props.row.UserAccountID"
                  >Edit</router-link></span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="false"
                  @click="deleteUserWithPrompt(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }
                "
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-delete-user"
      ok-only
      ok-variant="danger"
      ok-title="Delete User"
      modal-class="modal-danger"
      centered
      title="Confirm Delete User"
      @ok="confirmDeleteUser()"
    >
      <div v-if="userToDelete !== null">
        Are you sure you want to delete user {{ userToDelete.FirstName }}
        {{ userToDelete.LastName }} ({{ userToDelete.EMail }})? This cannot be
        undone.
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BBreadcrumb,
    BButton,
    BModal,
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      userToDelete: null,
      columns: [
        {
          label: "Name",
          field: "fullName",
        },
        {
          label: "Email",
          field: "EMail",
        },
        {
          label: "User Role",
          field: "Roles",
        },
        {
          label: "Status",
          field: "Status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
      breadcrumbItems: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: "Users & Permissions",
          href: '/admin/users-and-permissions'
        },
        {
          text: "Users",
          active: true,
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    isHR() {
      return this.$route.meta.forHR
    }
  },
  watch: {
    searchTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadUserPage(1);
          this.awaitingSearch = false;
        }, 750); // ms delay search debounce
      }
      this.awaitingSearch = true;
    },
  },
  async mounted() {
    this.loadUserPage(1);
  },
  methods: {
    goToAddNewUsers() {
      // routes to add new users form probally a better way to handle SPA route - Looking into the best pattern AJ
      this.$router.push("/add-new-users");
    },
    loadUserPage(pageNumber) {
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      if (this.searchTerm.length) {
        // short circuit to search

        apiService
          .get(
            "users/search?userTypes=User,Investigator&q=" +
              encodeURIComponent(this.searchTerm) +
              "&skip=" +
              startIndex +
              "&max=" +
              this.pageLength
          )
          .then((response) => {
            this.loadUserResults(response.data);
          });

        return;
      }

      apiService
        .get("users/user,investigator?skip=" + startIndex + "&max=" + this.pageLength)
        .then((response) => {
          this.loadUserResults(response.data);
        });
    },
    loadUserResults(responseData) {
      this.currentUsersObj = responseData;

      for (var user of this.currentUsersObj.Users) {
        user.fullName = user.LastName + ', ' + user.FirstName;
      }

      this.rows = responseData.Users;
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    onPageChanged(bvEvent, page) {
      //console.log('page changed to ' + page);
      this.loadUserPage(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadUserPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
      //console.log('Page Size changed to ' + this.pageLength);
    },
    deleteUserWithPrompt(userObj) {
      this.userToDelete = userObj;
      this.$bvModal.show("modal-delete-user");
    },
    confirmDeleteUser() {
      if (!this.userToDelete) {
        return;
      }
      apiService
        .delete("user/" + this.userToDelete.UserAccountID)
        .then((resp) => {
          if (resp) {
            this.showToast("success", "top-center", 4000, "User deleted");
            this.loadUserPage(this.currentPage);
          }
        });
      this.userToDelete = null;
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "InfoIcon",
            text: data,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
};
</script>

<style>
/* overide classes just for this view better pattern than inline styles on bootstrap components aj */
[dir] .form-group {
  margin-bottom: 0;
}
.btn--add-users,
.input-group-text {
  border-radius: 0;
}
[dir] .input-group > .form-control {
  height: 2.8em;
}
</style>
